import i18n from "i18next";
import { initReactI18next } from "react-i18next";


const resources = {
    en: {
        overview: require("./en/overview.json"),
        forms: require("./en/forms.json"),
        pdf: require("./en/pdf.json"),
        notification: require('./en/notification.json'),
        about: require('./en/about.json'),
        modal: require('./en/modal.json')
    },
    fr: {
        overview: require("./fr/overview.json"),
        forms: require("./fr/forms.json"),
        pdf: require("./fr/pdf.json"),
        notification: require('./fr/notification.json'),
        about: require('./fr/about.json'),
        modal: require('./fr/modal.json')
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;