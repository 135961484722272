import React from "react"
import {Fade} from "react-awesome-reveal";


export default function MyFade(props) {
    return(
        <Fade style={{width: '100%'}}>
            {props.children}
        </Fade>
    )
}