import React from "react"
import { useTranslation } from 'react-i18next';
import Input from "../../../components/forms/Input"
import Select from "../../../components/forms/Select"
import Slider from "../../../components/forms/Slider";
import classes from "./sections.module.scss"
import useData from "../../../helpers/hooks/useData";
import MyFade from "../../../components/MyFade";
import StringLister from "../../../components/forms/StringLister";



const Profile = () => {

        const { t } = useTranslation(['forms'])
        const { data, updateProperties } = useData()


        return (
            <MyFade>
                <div className={classes.container}>
                    <div className={classes.column}>
                        <Input title={t('profile.position.title')} placeholder={t('profile.position.placeholder')}
                               data={data} properties={["profile", "position"]} update={updateProperties} />

                        <Select title={t('profile.type.title')} options={t('profile.type.options', { returnObjects: true })}
                                data={data} properties={["profile", "type"]} update={updateProperties} />

                        <Slider title={t('profile.experience.title')}
                                data={data} properties={["profile", "experience"]} update={updateProperties} />

                        <hr className={classes.hSeparator}/>

                        <Select title={t('profile.nationality.title')} options={t('profile.nationality.options', { returnObjects: true })}
                                data={data} properties={["profile", "nationality"]} update={updateProperties} />

                        <Input title={t('profile.age.title')}
                               data={data} properties={["profile", "age"]} update={updateProperties} />
                    </div>

                    <hr className={classes.vSeparator}/>

                    <div className={classes.column}>
                        <StringLister title={t('profile.keys.title')} placeholder={t('profile.keys.placeholder')}
                                data={data} properties={["profile", "keys"]} update={updateProperties}/>
                    </div>
                </div>
            </MyFade>
        )
}


export default Profile