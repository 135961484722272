import React, { useState } from "react";
import classes from "./forms.module.scss";
import useSettings from "../../helpers/hooks/useSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { store as notificationStore } from "react-notifications-component";
import Notification from "../Notifications";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MyFade from "../MyFade";
import { reorder } from "../../helpers/array-manager";

export default function TextareaLister(props) {
  const { t } = useTranslation(["notification"]);
  const isEditable = useSettings().settings.edit;
  const [newObject, setNewObject] = useState({
    title: props.list[0],
    content: "",
  });
  let objects = props.data;
  props.properties.forEach((prop) => {
    objects = objects[prop];
  });
  const isObjectExisting =
    objects.find((object) => object.title === newObject.title) !== undefined;

  const handleChange = (event) => {
    setNewObject({
      title: event.target.value,
      content: "",
    });
  };

  const addNewObject = () => {
    if (objects.length < 8) {
      const updated_objects = [...objects];
      updated_objects.push(newObject);
      props.update(props.properties, updated_objects);
    } else
      notificationStore.addNotification({
        insert: "bottom",
        container: "bottom-left",
        content: <Notification text={t("warning.maxTable")} type={"warning"} />,
        dismiss: {
          duration: 5000,
        },
      });
  };

  const removeObject = (id) => {
    const updated_objects = [...objects];
    updated_objects.splice(id, 1);
    props.update(props.properties, updated_objects);
  };

  const onDragEnd = (result) => {
    if (!result.destination || !isEditable) return;

    const updated_objects = reorder(
      [...objects],
      result.source.index,
      result.destination.index
    );

    props.update(props.properties, updated_objects);
  };

  const onContentUpdate = (content, id) => {
    const updated_objects = [...objects];
    updated_objects[id].content = content;
    props.update(props.properties, updated_objects);
  };

  return (
    <div
      className={
        isEditable ? classes.group : classes.group + " " + classes.disabled
      }
      style={{ height: "100%" }}
    >
      <div className={classes.lister}>
        <div className={classes.top}>
          <p className={classes.text}>{props.title} :</p>

          <div className={classes.right}>
            <select disabled={!isEditable} onChange={handleChange}>
              {props.list.map((tech, index) => (
                <option key={index}>{tech}</option>
              ))}
            </select>
            <button
              onClick={addNewObject}
              disabled={!(isEditable && !isObjectExisting)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classes.keys}
              >
                {objects.map((object, index) => (
                  <MyFade key={index}>
                    <Draggable
                      key={index.toString()}
                      draggableId={index.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classes.key + " " + classes.complexList}
                        >
                          <ListItem
                            isEditable={isEditable}
                            object={object}
                            updateContent={(content) =>
                              onContentUpdate(content, index)
                            }
                            removeObject={() => removeObject(index)}
                          />
                        </div>
                      )}
                    </Draggable>
                  </MyFade>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

const ListItem = (props) => {
  const [count, setCount] = useState(
    props !== null ? props.object.content.length : 0
  );
  const { t } = useTranslation(["forms"]);

  return (
    <>
      <div className={classes.header}>
        <p>{props.object.title}</p>
        <button onClick={props.removeObject} disabled={!props.isEditable}>
          <FontAwesomeIcon icon={faMinus} />
        </button>
      </div>

      <textarea
        value={props.object.content}
        maxLength="3500"
        onChange={(e) => {
          props.updateContent(e.currentTarget.value);
          setCount(e.target.value.length);
        }}
        disabled={!props.isEditable}
      />
      <div className={classes.charCount} style={{ width: "100%" }}>
        <span>{count}</span>
        <span>/3500 {t("text.counter")}</span>
      </div>
    </>
  );
};
