import React from "react"
import { useTranslation } from 'react-i18next';
import classes from "./sections.module.scss"
import useData from "../../../helpers/hooks/useData";
import MyFade from "../../../components/MyFade";
import SelectLister from "../../../components/forms/SelectLister";



const Language = () => {

        const { t } = useTranslation(['forms'])
        const { data, updateProperties } = useData()


        return (
            <MyFade>
                <div className={classes.container}>
                    <div className={classes.column}>
                        <SelectLister title={t('language.title')} data={data} 
                                      properties={["languages"]} update={updateProperties} 
                                      list={t('language.list', {returnObjects: true})}
                                      sublist={t('language.sublist', {returnObjects: true})}/>
                    </div>
                </div>
            </MyFade>
        )
}


export default Language