import axios from "axios";
import {url} from "../conf/env";
import Cookies from "js-cookie";


/**
 * Allow to send an asynchronous ajax GET call to the API. Return a data object of an API Platform Jsonld response.
 * @method GET
 * @param link | for example : "/api/forecasts".
 * @returns {Promise<data>}
 * @see https://localhost:8001/api for an overview of the data object construction.
 */
export function query_api_get(link) {
    return new Promise((fetch) => {
        axios.get(url.api + link + "?auth_token=" + Cookies.get("sso-skills-token"), {headers:{"accept": "application/ld+json"}})
            .then((response) => fetch(response.data))
            .catch((error) => {
                throw_error(error);
                fetch(null);
            })
    });
}


/**
 * Allow to send an asynchronous ajax POST call to the API. Return an axios response.
 * @method POST
 * @param link | for example : "/api/forecasts".
 * @param data | see https://localhost:8001/api for an overview of the data object construction.
 * @param headers | optional http headers, can be null.
 * @returns {Promise<AxiosResponse<T>>}
 * @see https://github.com/axios/axios
 */
export function query_api_post(link, data, headers) {
    data.auth_token = Cookies.get("sso-skills-token");
    return axios.post(url.api + link,
        data,
        {headers:headers});
}


/**
 * Allow to send an asynchronous ajax PUT call to the API. Return an axios response.
 * @method PUT
 * @param link | for example : "/api/forecasts".
 * @param data | see https://localhost:8001/api for an overview of the data object construction.
 * @param headers | optional http headers, can be null.
 * @returns {Promise<AxiosResponse<T>>}
 * @see https://github.com/axios/axios
 */
export function query_api_put(link, data, headers) {
    data.auth_token = Cookies.get("sso-skills-token");
    return axios.put(url.api + link,
        data,
        {headers:headers});
}


export function query_api_delete(link) {
    return axios.delete(url.api + link + "?auth_token=" + Cookies.get("sso-skills-token"))
}


/**
 * Allow to send an asynchronous ajax GET call to an external link. Return an axios response.
 * @method GET
 * @param link | for example : "https://google.fr".
 * @param headers | optional http headers, can be null.
 * @returns {Promise<AxiosResponse<T>>}
 * @see https://github.com/axios/axios
 */
export function query_external_get(link, headers) {
    return axios.get(link, {headers:headers});
}


/**
 * Allows to handle errors from previous Ajax methods.
 * @param err
 */
function throw_error(err){
    console.error(err);
}
