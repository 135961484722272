import React from "react"
import { useTranslation } from 'react-i18next';
import classes from "./sections.module.scss"
import MyFade from "../../../components/MyFade";


export default function About() {

    const t = useTranslation(['about']).t;


    return(
        <MyFade>
            <div className={classes.container}>
                <div className={classes.about}>
                    <h1>{t('title')}</h1>
                    <p>{t('description')}</p>
                    <h2>{t('info')}</h2>
                    <ul>
                        {t('list', {returnObjects: true }).map((text, index) =>
                            <li key={index}>{text}</li>
                        )}
                    </ul>
                    <a target="_blank" rel="noopener noreferrer" href={"https://drive.google.com/file/d/1lShdQIcpILterocf8RUXrJUk0FtuddTu/view?usp=sharing"}>
                        <h2>{t('example')}</h2>
                    </a>
                </div>
            </div>
        </MyFade>
    )
}