import React from "react"
import classes from "./forms.module.scss"
import useSettings from "../../helpers/hooks/useSettings";


const Select = (props) => {

    const isEditable = useSettings().settings.edit

    let value = props.data
    props.properties.forEach((prop) => {
        value = value[prop]
    })


    const handleChange = (event) => {
        props.update(props.properties, event.target.value)
    }


    return (
        <div className={(isEditable)? classes.group : classes.group + " " + classes.disabled}>
            <p className={classes.text}>{props.title} :</p>
            <select onChange={handleChange} value={value} disabled={!isEditable}>
                {props.options.map((option, index) => 
                    <option key={index}>{option}</option>
                )}
            </select>
        </div>
    )
}


export default Select