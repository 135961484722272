import React, { useEffect, useState } from 'react';
import {Route, BrowserRouter as Router} from "react-router-dom"
import Header from "./components/Navigation/Navbar"
import AuthenticationFail from "./pages/Authentication/fail"
import {checkToken} from "./helpers/security"
import Cookies from "js-cookie";
import { routes } from './conf/routes';
import {DataProvider} from "./helpers/context/DataContext";
import {getInitialSkillsData} from "./providers/provider";
import {SettingsProvider} from "./helpers/context/SettingsContext";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-responsive-modal/styles.css';
import 'react-notifications-component/dist/theme.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import ReactNotification from 'react-notifications-component'
import EventBus from "eventbusjs";
import Loading from "./components/Loading";


let routerHistory = null

const App = (props) => {

    const authData = props.authData
    const [authTokenIsCorrect, setAuthTokenStatus] = useState(true)
    const [currentEmployeeSkills, setCurrentEmployeeSkills] = useState(null)


    useEffect(() => {
        routerHistory = require("history").createBrowserHistory()
        routerHistory.listen((location, action) =>
            new Promise((resolveToken) => checkToken(Cookies.get("sso-skills-token"), resolveToken))
                .then((token) => setAuthTokenStatus(token !== null))
        )

        EventBus.addEventListener('provider-hydrated', loadCurrentEmployeesData);
        return () => EventBus.removeEventListener('provider-hydrated', loadCurrentEmployeesData)
    }, [])


    const loadCurrentEmployeesData = () => {
        const _skillsData = getInitialSkillsData()
        setCurrentEmployeeSkills({..._skillsData.selected_employee.qualifications})
    }


    if(authTokenIsCorrect)
        return(
            <Router history={routerHistory}>

                <ReactNotification/>
                <Header userFirstName={authData.firstName} userLastName={authData.lastName}/>

                <div id={"app_content"}>

                    {(currentEmployeeSkills !== null)?
                        <SettingsProvider>
                            <DataProvider skillsData={currentEmployeeSkills}>
                                {routes.map((route, index) =>
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        component={() => route.comp(authData)}
                                    />
                                )}
                            </DataProvider>
                        </SettingsProvider>

                        :

                        <Loading message={"Loading Skills data ..."}/>
                    }
                </div>
            </Router>
        )

    else
        return (
            <div id={"app_content"}>
                <AuthenticationFail/>
            </div>
        )
}


export default App