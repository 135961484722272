import React from "react";
import {PDFViewer} from "@react-pdf/renderer";
import useData from "../../helpers/hooks/useData";
import {styles} from "../../components/pdf/document.style"
import {useTranslation} from "react-i18next";
import classes from "./pdf.module.scss"
import useSettings from "../../helpers/hooks/useSettings";
import Document from "../../components/pdf/Document";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import downloadCv from "../../helpers/download-object";
import {getInitialSkillsData} from "../../providers/provider";


export default function Pdf() {

    const selectedEmployee = getInitialSkillsData().selected_employee
    const data = useData().data
    const updateLangOption = useSettings().updateLangOption
    const {t, i18n} = useTranslation(['pdf', 'notification']);


    const setLanguage = (new_lang) => {
        updateLangOption(new_lang)
    }


    const generatePdf = () => {
        downloadCv(
            <Document fullName={selectedEmployee.firstName + " " + selectedEmployee.lastName.toUpperCase()} data={data}/>,
            selectedEmployee.firstName + "_" + selectedEmployee.lastName.toUpperCase(),
            t('notification:success.download')
        )
    }


    return (
        <div id={"page"} className={classes.pdf}>
            <PDFViewer width={"100%"} height={"100%"} style={styles.pdf}>
                <Document fullName={selectedEmployee.firstName + " " + selectedEmployee.lastName.toUpperCase()} data={data}/>
            </PDFViewer>

            <div className={classes.tools}>
                <hr/>
                <div className={classes.lang}>
                    <button onClick={() => setLanguage("fr")}
                        className={(i18n.language === "fr")? classes.active + " " + classes.action : classes.action}>FR</button>
                    <button onClick={() => setLanguage("en")}
                        className={(i18n.language === "en")? classes.active + " " + classes.action : classes.action}>EN</button>
                </div>
                <hr/>

                <button className={classes.action} onClick={generatePdf}>
                    <FontAwesomeIcon icon={faDownload}/>
                </button>
            </div>
        </div>
    )
}