import React from "react"
import classes from "./forms.module.scss"
import ReactSlider from 'react-slider'
import useSettings from "../../helpers/hooks/useSettings";


const Slider = (props) => {

    const isEditable = useSettings().settings.edit

    let value = props.data
    props.properties.forEach((prop) => {
        value = value[prop]
    })


    const handleChange = (new_value) => {
        props.update(props.properties, new_value)
    }


    return(
        <div className={(isEditable)? classes.group : classes.group + " " + classes.disabled}>
            <p className={classes.text}>{props.title} :</p>
            <ReactSlider
                className={classes.slider}
                markClassName={classes.mark}
                disabled={!isEditable}
                min={1}
                marks={[5, 10, 15]}
                defaultValue={value}
                max={16}
                onChange={handleChange}
                thumbClassName={classes.thumb}
                trackClassName={classes.track}
                withTracks={true}
                renderThumb={(props, state) => <div {...props}>{(state.valueNow > 15)? "+" : state.valueNow}</div>}
                renderTrack={(props, state) => (
                    <div {...props}>
                        <div style={{backgroundColor: (state.index === 0)? 'rgb(29,195,231)' : 'rgba(255,255,255,0.3'}}/>
                    </div>
                )}
            />
        </div>
    )
}


export default Slider