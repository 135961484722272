import {StyleSheet} from '@react-pdf/renderer'


export const styles = StyleSheet.create({

    pdf: {
        border: 'none'
    },

    display: {
        justifyContent: 'center',
        flexDirection: 'row',
        padding: '0 70px 80px 70px',
    },

    logo: {
        width: '115px',
        height: '40px',
        margin: '50px 0 10px 335px'
    },

    content: {
        width: '450px',
        margin: '10px 0 50px 0',
        textAlign: 'center'
    },

    disclaimer: {
        fontSize: 7,
        textAlign: 'center',
        position: 'absolute',
        bottom: 40,
        color: '#666666',
        left: 0,
        right: 0,
        height: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
})
