// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification_notification__2W6At {\n  width: 100%;\n  background-color: #1dc3e7;\n  display: flex;\n  align-items: center;\n  padding: 10px 20px;\n  color: white;\n  border-radius: 3px;\n  border-left: 10px solid rgba(0, 0, 0, 0.3);\n}\n.notification_notification__2W6At svg {\n  width: 25px !important;\n  height: 25px;\n}\n.notification_notification__2W6At p {\n  margin: 0 0 0 20px;\n  font-size: 14px;\n}", "",{"version":3,"sources":["webpack://src/components/Notifications/notification.module.scss","webpack://src/res/colors.scss"],"names":[],"mappings":"AAGA;EACI,WAAA;EACA,yBCLG;EDMH,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,0CAAA;AAFJ;AAII;EACI,sBAAA;EACA,YAAA;AAFR;AAKI;EACI,kBAAA;EACA,eAAA;AAHR","sourcesContent":["@import \"../../res/colors.scss\";\n\n\n.notification{\n    width: 100%;\n    background-color: $blue;\n    display: flex;\n    align-items: center;\n    padding: 10px 20px;\n    color: white;\n    border-radius: 3px;\n    border-left: 10px solid rgba(0,0,0,0.3);\n\n    svg{\n        width: 25px !important;\n        height: 25px;\n    }\n\n    p{\n        margin: 0 0 0 20px;\n        font-size: 14px;\n    }\n\n}","$blue: rgb(29,195,231);\r\n@function blue_opacity($opacity){\r\n  @return rgba(29,195,231, $opacity);\r\n}\r\n$dark_blue: #394C5F;\r\n\r\n$orange: rgb(246, 189, 96);\r\n@function orange_opacity($opacity){\r\n  @return rgba(246,189,96, $opacity);\r\n}\r\n\r\n$dark: #272727;\r\n\r\n$black: rgb(28,28,28);\r\n@function black_opacity($opacity){\r\n  @return rgba(40,40,40, $opacity);\r\n}\r\n\r\n$gray-strong: rgb(70,70,70);\r\n$gray-medium: rgb(120,120,120);\r\n$gray-little: rgb(200,200,200);\r\n$gray-thin: #F0F0F0;\r\n\r\n$transparent: rgba(0,0,0,0)\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": "notification_notification__2W6At"
};
export default ___CSS_LOADER_EXPORT___;
