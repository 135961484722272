import React, { useEffect, useState } from "react";
import classes from "./profile.module.scss";
import useData from "../../helpers/hooks/useData";
import { useTranslation } from "react-i18next";
import MainButton from "../buttons/MainButton";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { getInitialSkillsData } from "../../providers/provider";
import ModalChangeUser from "../modal";
import EventBus from "eventbusjs";

const Usercard = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const data = useData().data;
  const t = useTranslation(["overview"]).t;

  const selectedEmployee = getInitialSkillsData().selected_employee;
  const roles = props.authData.payload.roles;
  const username =
    selectedEmployee.firstName.charAt(0) + selectedEmployee.lastName.charAt(0);
  const isUser =
    !roles.includes("ROLE_MANAGER") &&
    !roles.includes("ROLE_ADMIN") &&
    !roles.includes("ROLE_LEADER");

  useEffect(() => {
    EventBus.addEventListener(
      "open-changeuser-modal",
      handleModalChangeUserOpen
    );
    return () =>
      EventBus.removeEventListener(
        "open-changeuser-modal",
        handleModalChangeUserOpen
      );
  }, []);

  const handleModalChangeUserOpen = () => {
    setModalOpen(true);
  };

  const handleModalChangeUserClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className={classes.profile}>
        <span className={classes.picture}>{username}</span>
        <h2>{selectedEmployee.firstName + " " + selectedEmployee.lastName}</h2>
        <div className={classes.job}>
          <h3>
            {data.profile.position === ""
              ? t("user.undefined")
              : data.profile.position}
          </h3>
        </div>
        <p>
          {data.profile.nationality === ""
            ? t("user.undefined")
            : data.profile.nationality +
              ", " +
              data.profile.age +
              " " +
              t("user.age")}
        </p>
        {isUser ? null : (
          <MainButton
            text={t("buttons.change")}
            style={{ marginTop: "auto" }}
            icon={faRedoAlt}
            onClick={handleModalChangeUserOpen}
          />
        )}
      </div>

      <ModalChangeUser
        open={modalOpen}
        handleClose={handleModalChangeUserClose}
        authData={props.authData}
      />
    </>
  );
};

export default Usercard;
