import React, {useState} from "react"
import classes from "./forms.module.scss"
import useSettings from "../../helpers/hooks/useSettings";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import MyFade from "../MyFade";
import {reorder} from "../../helpers/array-manager";
import ReactSlider from "react-slider";
import {store as notificationStore} from "react-notifications-component";
import Notification from "../Notifications";
import {useTranslation} from "react-i18next";


const SliderLister = (props) => {

    const { t } = useTranslation(['notification'])
    const isEditable = useSettings().settings.edit
    const [newObject, setNewObject] = useState({
        title: props.list[0],
        level: 2
    })
    let objects = props.data
    props.properties.forEach((prop) => {
        objects = objects[prop]
    })
    const isObjectExisting = objects.find((object) => object.title === newObject.title) !== undefined


    const handleChange = (event) => {
        setNewObject({
            title: event.target.value,
            level: 2
        })
    }


    const addNewObject = () => {
        if(objects.length < 14) {
            const updated_objects = [...objects]
            updated_objects.push(newObject)
            props.update(props.properties, updated_objects)
        }
        else
            notificationStore.addNotification({
                insert: "bottom",
                container: "bottom-left",
                content: <Notification text={t("warning.maxSkills")} type={"warning"}/>,
                dismiss: {
                    duration: 5000
                }
            })
    }


    const removeObject = (id) => {
        const updated_objects = [...objects]
        updated_objects.splice(id, 1)
        props.update(props.properties, updated_objects)
    }


    const onDragEnd = (result) => {
        if (!result.destination || !isEditable) return;

        const updated_objects = reorder(
            [...objects],
            result.source.index,
            result.destination.index
        );

        props.update(props.properties, updated_objects)
    }


    const handleSliderChange = (new_level, id) => {
        const updated_objects = [...objects]
        updated_objects[id].level = new_level
        props.update(props.properties, updated_objects)
    }


    return(
        <div className={(isEditable)? classes.group : classes.group + " " + classes.disabled} style={{height: '100%'}}>
            <div className={classes.lister}>
                <div className={classes.top}>
                    <p className={classes.text}>{props.title} :</p>

                    <div className={classes.right}>
                        <select disabled={!isEditable} onChange={handleChange}>
                            {props.list.map((tech, index) =>
                                <option key={index}>{tech}</option>
                            )}
                        </select>
                        <button onClick={addNewObject} disabled={!(isEditable && !isObjectExisting)}><FontAwesomeIcon icon={faPlus}/></button>
                    </div>
                </div>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}
                                 className={classes.keys}>

                                {objects.map((object, index) =>
                                    <MyFade key={index}>
                                        <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                                            {(provided, snapshot) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                     className={classes.key}>

                                                    <div className={classes.container}>
                                                        <p>{object.title}</p>
                                                        <div contentEditable={true}>
                                                            <ReactSlider
                                                                className={classes.slider}
                                                                markClassName={classes.mark}
                                                                disabled={!isEditable}
                                                                min={1}
                                                                marks={[1,2,3,4,5]}
                                                                value={object.level}
                                                                max={5}
                                                                onChange={(value) => handleSliderChange(value, index)}
                                                                thumbClassName={classes.thumb}
                                                                trackClassName={classes.track}
                                                                withTracks={true}
                                                                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                                                renderTrack={(props, state) => (
                                                                    <div {...props}>
                                                                        <div style={{backgroundColor: (state.index === 0)? 'rgb(29,195,231)' : 'rgba(255,255,255,0.3'}}/>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <button onClick={() => removeObject(index)} disabled={!isEditable}><FontAwesomeIcon icon={faMinus}/></button>
                                                </div>
                                            )}
                                        </Draggable>
                                    </MyFade>
                                )}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    )
}


export default SliderLister