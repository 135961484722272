import React from "react"
import {Text, View} from "@react-pdf/renderer";
import Header from "../components/Header";
import {StyleSheet} from '@react-pdf/renderer'


export const styles = StyleSheet.create({
    container: {
        width: '100%'
    },

    item: {
        marginBottom: 20
    },

    line: {
        width: '100%',
        fontSize: 12,
        flexDirection: 'row',
    },

    name: {
        textAlign: 'left',
        fontFamily: 'Helvetica-Bold',
    },

    university: {
        textAlign: 'left',
        fontStyle: 'italic',
        color: '#555555',
    },

    period: {
        fontSize: 11,
        marginTop: 2,
        textAlign: 'left',
        opacity: 0.8,
        color: '#555555'
    },

    description: {
        fontSize: 12,
        marginTop: 5,
        textAlign: 'left',
        color: '#555555'
    }
})


export default function Project(props) {

    const {t} = props.pdfTranslation

    
    return(
        <>
            <View>
                <Header text={t('project.title')}/>
                <View style={styles.container}>
                    {props.data.map((project, index) =>
                        <View key={index} style={styles.item}>
                            <View style={styles.line}>
                                <Text style={styles.name}>{project.name + " - "}</Text>
                                <Text style={styles.university}>{project.type}</Text>
                            </View>
                            <Text style={styles.period}>{project.period}</Text>
                            <Text style={styles.description}>{project.content}</Text>
                        </View>
                    )}
                </View>
            </View>
        </>
    )
}