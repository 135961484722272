import {pdf} from "@react-pdf/renderer";
import {store as notificationStore} from "react-notifications-component";
import Notification from "../components/Notifications";


export default function downloadCv(cv, fullname, notification){

    pdf(cv).toBlob()
        .then((pdf) => {
            const pdfUrl = URL.createObjectURL(pdf);
            const link = document.createElement("a");

            const date = new Date()
            const cv_date = ((date.getMonth().toString().length < 2)? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + date.getFullYear()

            link.href = pdfUrl;
            link.download = fullname + "_esokia_" + cv_date + ".pdf";

            document.body.appendChild(link);
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                })
            );
            document.body.removeChild(link);

            notificationStore.addNotification({
                insert: "bottom",
                container: "bottom-left",
                content: <Notification text={notification} type={"success"}/>,
                dismiss: {
                    duration: 3000
                }
            })
        });
}