import React from "react"
import classes from "./main.module.scss"
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const MainButton = (props) => {

    const classesJoin = () => {
        let _classes = classes.btn
        if(props.disabled) _classes += " " + classes.disabled
        if(props.light) _classes += " " + classes.light
        return _classes
    }


    if(typeof props.to === "undefined")
        return(
            <button onClick={props.onClick} className={classesJoin()}
                    style={props.style} disabled={props.disabled}>
                <FontAwesomeIcon icon={props.icon}/> {props.text}
            </button>
        )


    else if(props.external)
        return(
            <a href={props.to} download={props.download} className={classesJoin()} style={props.style}>
                <FontAwesomeIcon icon={props.icon}/> {props.text}
            </a>
        )


    else{
        return (
            <Link to={props.to} className={classesJoin()} style={props.style}>
                <FontAwesomeIcon icon={props.icon}/> {props.text}
            </Link>
        )
    }
}


export default MainButton