import {query_api_get} from "../helpers/ajax";


const max_fail_loopback = 3;

let rawApiDataList = [
    "employees",
    "qualifications"
];


function getRawData(repetition, entity, resolve) {
    query_api_get("/api/" + entity)

        .then((response) =>{
            if(response['@id'] === "/api/" + entity) resolve(response["hydra:member"]);
            else return Promise.reject("Wrong data type returned");
        })

        .catch((err) => {
            if(repetition < max_fail_loopback)
                setTimeout(getRawData(repetition + 1, entity, resolve), 1000);
            else
                console.error(err);
        })
}


export default function getApiRawData(){

    let promises = [];
    rawApiDataList.forEach((entity) =>
        promises.push(new Promise((resolve) => getRawData(0, entity, resolve))));

    return Promise.all(promises);
}
