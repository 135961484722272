import React from "react"
import classes from "./forms.module.scss"
import useSettings from "../../helpers/hooks/useSettings";


const Input = (props) => {

    const isEditable = useSettings().settings.edit

    let value = props.data
    props.properties.forEach((prop) => {
        value = value[prop]
    })


    const handleChange = (event) => {
        props.update(props.properties, event.target.value)
    }


    return (
        <div className={(isEditable)? classes.group : classes.group + " " + classes.disabled}>
            <p className={classes.text}>{props.title} :</p>

            <input type={"text"} placeholder={props.placeholder}
                   value={value} disabled={!isEditable}
                   onChange={handleChange}/>
        </div>
    )
}


export default Input