import React from "react";
import classes from "./style.module.scss";
import loader from "../../res/icons/loader.svg";


export default class Loading extends React.Component {

    render() {

        return (
            <div className={classes.loading}>
                <h5>{this.props.message.toUpperCase()}</h5>
                <img src={loader} alt={"loader"}/>
            </div>
        )
    }
}
