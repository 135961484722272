import {query_api_post, query_api_put} from "../helpers/ajax";
import {getInitialSkillsData} from "./provider";


export default function saveSkills(updated_skills) {

    const http_promises = [];
    const employee_id = getInitialSkillsData().selected_employee_id

    Object.values(updated_skills).forEach((skill) => {
        if(skill.id === null)
            http_promises.push(query_api_post("/api/qualifications", {
                employee: `/api/employees/${employee_id}`,
                lang: skill.lang,
                profile: skill.profile,
                technical: skill.technical,
                languages: skill.languages,
                training: skill.training,
                experience: skill.experience,
                project: skill.project
            }))

        else
            http_promises.push(query_api_put(skill['@id'], {
                employee: skill.employee,
                lang: skill.lang,
                profile: skill.profile,
                technical: skill.technical,
                languages: skill.languages,
                training: skill.training,
                experience: skill.experience,
                project: skill.project
            }))
    })

    return Promise.all(http_promises)
}