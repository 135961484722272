export const url =
    process.env.REACT_APP_API_URL === undefined
        ? {
            forecast: "http://localhost:3000",
            reporting: "http://localhost:3001",
            holidays: "http://localhost:3002",
            skills: "http://localhost:3003",
            sso: "http://localhost:8000",
            api: "http://localhost:8001",
            mercure: "http://localhost:1000",
            topic: "http://localhost:8001"
        }
        : {
            forecast: process.env.REACT_APP_FORECAST_URL,
            reporting: process.env.REACT_APP_REPORTING_URL,
            holidays: process.env.REACT_APP_HOLIDAYS_URL,
            skills: process.env.REACT_APP_SKILLS_URL,
            sso: process.env.REACT_APP_SSO_URL,
            api: process.env.REACT_APP_API_URL,
            mercure: process.env.REACT_APP_MERCURE_URL,
            topic: process.env.REACT_APP_MERCURE_API_TOPIC_URL
        };
