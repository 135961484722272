import React from "react"
import {Text, View} from "@react-pdf/renderer";
import Header from "../components/Header";
import {StyleSheet} from '@react-pdf/renderer'


export const styles = StyleSheet.create({

    container: {
        textAlign: 'justify',
    },

    name: {
        color: '#666666',
        fontWeight: '900',
        marginBottom: '4px',
        fontSize: 18,
        fontFamily: 'Helvetica-Bold'
    },

    job: {
        color: '#4ec1e0',
        fontSize: 13,
        fontWeight: 800,
        fontFamily: 'Helvetica-Bold'
    },

    about: {
        marginTop: 2,
        fontSize: 11,
        color: '#a3a3a3'
    },

    keysContainer: {
        textAlign: 'justify',
        fontSize: 10,
        color: '#666666',
        marginLeft: '20px'
    }
})


export default function Profile(props) {

    const {t, i18n} = props.pdfTranslation


    return(
        <>
            <View style={styles.container}>
                <Text style={styles.name}>{props.fullName}</Text>
                <Text style={styles.job}>{props.data.position}</Text>
                <Text style={styles.about}>
                    {props.data.nationality}, {props.data.experience} {t('about.experience')} | {props.data.age} {t('about.age')}
                </Text>
            </View>

            <View>
                <Header text={(i18n.language === 'fr')? 
                    t('about.title') + " " + props.data.type : 
                    props.data.type + " " + t('about.title')}
                />

                <View style={styles.keysContainer}>
                    {props.data.keys.map((key, index) => 
                        <Text key={index}>- &nbsp;{key}</Text>
                    )}
                </View>
            </View>
        </>
    )
}