import React, { useState, createContext } from "react";
import {useTranslation} from "react-i18next";
import { store as notificationStore } from 'react-notifications-component';
import Notification from "../../components/Notifications"


const SettingsContext = createContext({});

export const SettingsProvider = ({ children }) => {

    const {t, i18n} = useTranslation(['notification'])
    const [notifID, setNotifID] = useState(null)
    const [settings, setSettings] = useState({
        edit: false,
    });


    const updateEditOption = (bool) => {
        const new_settings = {...settings}
        new_settings.edit = bool
        setSettings(new_settings)

        if(new_settings.edit){
            notificationStore.addNotification({
                insert: "bottom",
                container: "bottom-left",
                content: <Notification text={t('success.unlock')} type={"success"}/>,
                dismiss: {
                    duration: 3000                
                }
            })
            setNotifID(
                notificationStore.addNotification({
                    insert: "bottom",
                    container: "bottom-left",
                    content: <Notification text={t('warning.unlock')} type={"warning"}/>
                })
            )
        }
        else if(notifID !== null) notificationStore.removeNotification(notifID)
    }


    const updateLangOption = (new_lang) => {
        i18n.changeLanguage(new_lang)

        notificationStore.addNotification({
            insert: "bottom",
            container: "bottom-left",
            content: <Notification text={t('info.lang')} type={"info"}/>,
            dismiss: {
                duration: 3000                
            }
        }) 
    }


    return (
        <SettingsContext.Provider value={{ settings, updateEditOption, updateLangOption }}>
            {children}
        </SettingsContext.Provider>
    );
};


export default SettingsContext;