import getApiRawData from "./api-data-provider";
import EventBus from 'eventbusjs';


const initialSkillsData = {
    filled_employees: [],
    selected_employee: null,
    selected_employee_id: null
}


export function getInitialSkillsData() {
    return {...initialSkillsData}
}


export function changeSelectedEmployee(id) {
    initialSkillsData.selected_employee_id = id
    initialSkillsData.selected_employee = {...initialSkillsData.filled_employees.find((employee) => employee.id === id)}

    EventBus.dispatch("provider-hydrated");
    return initialSkillsData.selected_employee
}


export default function startSkillsDataLoading(authData){

    if(authData !== null) {
        getApiRawData().then((values) => {

            initialSkillsData.filled_employees = values[0].map((employee) => {
                const filled_employee = {...employee}
                filled_employee.qualifications = {}

                if (employee.qualifications.length === 0)
                    filled_employee.qualifications = {...default_qualifications}

                else employee.qualifications.forEach((uri) => {
                    const qualification = values[1].find((el) => el['@id'] === uri)
                    filled_employee.qualifications[qualification.lang] = qualification
                })

                return filled_employee
            })

            if(authData.payload.roles.includes("ROLE_ADMIN")) initialSkillsData.selected_employee_id =  initialSkillsData.filled_employees[0].id
            else initialSkillsData.selected_employee_id = authData.payload.api_id

            initialSkillsData.selected_employee = {...initialSkillsData.filled_employees.find((employee) => employee.id === initialSkillsData.selected_employee_id)}

            EventBus.dispatch("provider-hydrated")
            if(authData.payload.roles.includes("ROLE_ADMIN")) setTimeout(() => EventBus.dispatch("open-changeuser-modal"), 400)
        })
    }
}


export const default_qualifications = {
    fr: {
        id: null,
        lang: "fr",
        profile: {
            position: "",
            type: "",
            experience: 0,
            nationality: "",
            age: 20,
            keys: []
        },
        technical: {
            masteredTechnologies: [],
            skillsTable: []
        },
        languages: [],
        training: [],
        experience: [],
        project: []
    },

    en: {
        id: null,
        lang: "en",
        profile: {
            position: "",
            type: "",
            experience: 0,
            nationality: "",
            age: 20,
            keys: []
        },
        technical: {
            masteredTechnologies: [],
            skillsTable: []
        },
        languages: [],
        training: [],
        experience: [],
        project: []
    }
}
