import React from "react"
import {Page, View, Text, Document as RootDocument, Image} from "@react-pdf/renderer";
import {styles} from "./document.style"
import logo from "../../res/images/logo.png";
import Profile from "./sections/Profile";
import {useTranslation} from "react-i18next";
import Technical from "./sections/Technical";
import Language from "./sections/Language"
import Formation from "./sections/Formation";
import Experience from "./sections/Experience";
import Project from "./sections/Project";


export default function Document(props) {

    const {t, i18n} = useTranslation(['pdf']);

    return(
        <RootDocument>
            <Page size={"A4"} style={styles.display} wrap>
                <View>
                    <Image src={logo} style={styles.logo} fixed/>

                    <View style={styles.content}>
                        <Profile data={props.data.profile} fullName={props.fullName} pdfTranslation={{t, i18n}}/>
                        <Technical data={props.data.technical} pdfTranslation={{t, i18n}}/>
                        <Language data={props.data.languages} pdfTranslation={{t, i18n}}/>
                        <Formation data={props.data.training} pdfTranslation={{t, i18n}}/>
                        <Experience data={props.data.experience} pdfTranslation={{t, i18n}}/>
                        <Project data={props.data.project} pdfTranslation={{t, i18n}}/>
                    </View>
                </View>

                <View style={styles.disclaimer} fixed>
                    <Text>{t('address')}</Text>
                    <Text style={{fontFamily: 'Helvetica-Oblique', opacity: 0.7}}>{t('disclaimer')}</Text>
                </View>
            </Page>
        </RootDocument>
    )
}