import {StyleSheet, Text, View} from '@react-pdf/renderer'
import React from 'react';


export default function Header(props) {

    const styles = StyleSheet.create({

        headerContainer: {
            flexDirection: 'row',
            marginTop: 30,
            marginBottom: 8,
            borderBottom: '1 solid #a3a3a3',
            paddingBottom: 8,
            textAlign: 'justify',
            fontFamily: 'Helvetica-Bold'
        },

        header: {
            color: '#4ec1e0',
            fontSize: 13,
            fontWeight: 800,
            fontFamily: 'Helvetica-Bold',
            textTransform: "uppercase"
        }
    })


    return (
        <View style={styles.headerContainer}>
            <Text style={styles.header}>{props.text}</Text>
        </View>
    )
}
