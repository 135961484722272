import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import classes from "./style.module.scss";
import { Modal } from "react-responsive-modal";
import { DateRange } from "react-date-range";
import moment from "moment";

export default function RangePicker(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const onRangeChange = (item) => {
    props.setRangeState(item.selection);
    props.onChange(item.selection);
  };

  return (
    <>
      <button
        disabled={props.disabled}
        className={classes.btn}
        onClick={() => setModalOpen(true)}
      >
        {props.rangeState.startDate !== null &&
        props.rangeState.endDate !== null ? (
          <span>
            {moment(props.rangeState.startDate).format("MMM YYYY") +
              " - " +
              moment(props.rangeState.endDate).format("MMM YYYY")}
          </span>
        ) : (
          <FontAwesomeIcon icon={faCalendar} />
        )}
      </button>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)} center>
        <div className={classes.modalContainer}>
          <DateRange
            editableDateInputs={true}
            onChange={onRangeChange}
            moveRangeOnFirstSelection={false}
            ranges={[props.rangeState]}
            color={"rgb(29,195,231)"}
            rangeColors={["rgb(29,195,231)"]}
          />
        </div>
      </Modal>
    </>
  );
}
