// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_btn__3PcHi {\n  width: auto !important;\n  min-width: 30px !important;\n  background-color: #1dc3e7;\n}\n.style_btn__3PcHi span {\n  padding: 0 5px;\n  font-weight: 500;\n  font-size: 13px;\n}\n\n.style_modalContainer__QsVqR {\n  padding-top: 40px;\n}", "",{"version":3,"sources":["webpack://src/components/RangePicker/style.module.scss","webpack://src/res/colors.scss"],"names":[],"mappings":"AAGA;EACI,sBAAA;EACA,0BAAA;EACA,yBCNG;ADIP;AAII;EACI,cAAA;EACA,gBAAA;EACA,eAAA;AAFR;;AAMA;EACI,iBAAA;AAHJ","sourcesContent":["@import \"../../res/colors.scss\";\n\n\n.btn{\n    width: auto !important;\n    min-width: 30px !important;\n    background-color: $blue;\n\n    span{\n        padding: 0 5px;\n        font-weight: 500;\n        font-size: 13px\n    }\n}\n\n.modalContainer{\n    padding-top: 40px;\n}","$blue: rgb(29,195,231);\r\n@function blue_opacity($opacity){\r\n  @return rgba(29,195,231, $opacity);\r\n}\r\n$dark_blue: #394C5F;\r\n\r\n$orange: rgb(246, 189, 96);\r\n@function orange_opacity($opacity){\r\n  @return rgba(246,189,96, $opacity);\r\n}\r\n\r\n$dark: #272727;\r\n\r\n$black: rgb(28,28,28);\r\n@function black_opacity($opacity){\r\n  @return rgba(40,40,40, $opacity);\r\n}\r\n\r\n$gray-strong: rgb(70,70,70);\r\n$gray-medium: rgb(120,120,120);\r\n$gray-little: rgb(200,200,200);\r\n$gray-thin: #F0F0F0;\r\n\r\n$transparent: rgba(0,0,0,0)\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "style_btn__3PcHi",
	"modalContainer": "style_modalContainer__QsVqR"
};
export default ___CSS_LOADER_EXPORT___;
