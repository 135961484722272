import React, { useState, createContext } from "react";
import {useTranslation} from "react-i18next";

const DataContext = createContext({});


export const DataProvider = ({ children, skillsData }) => {

    const [allSkills, setAllSkills] = useState(skillsData);
    const i18n = useTranslation().i18n;


    const updateData = (data) => {
        setAllSkills(data)
    }


    const updateProperties = (properties, data) => {
        const new_data = {...allSkills}

        if(properties.length === 1) new_data[i18n.language][properties[0]] = data
        else new_data[i18n.language][properties[0]][properties[1]] = data

        setAllSkills(new_data)
    }


    const getAllSkills = () => {
        return {...allSkills}
    }


    return (
        <DataContext.Provider value={{ data: allSkills[i18n.language], updateProperties, updateData, getAllSkills }}>
            {children}
        </DataContext.Provider>
    );
};


export default DataContext;