import React from "react"
import {Text, View} from "@react-pdf/renderer";
import Header from "../components/Header";
import {StyleSheet} from '@react-pdf/renderer'


export const styles = StyleSheet.create({

    containerMaster: {
        width: '100%',
        flexWrap: 'wrap',
        marginTop: 15,
        maxHeight: '23vh',
        justifyContent: 'space-between',
        flexDirection: "row"
    },

    object: {
        flexDirection: "row",
        width: '46%',
        alignItems: 'center',
        justifyContent: "space-between",
        marginBottom: 1
    },

    title: {
        textAlign: 'left',
        fontSize: 9,
        fontFamily: 'Helvetica-Bold',
        color: '#555555',
    },

    level: {
        flexDirection: "row"
    },

    item: {
        width: 25,
        height: 25,
        marginLeft: 1,
        backgroundColor: '#9ec4e7',
        justifyContent: 'center',
    },

    indicator: {
        fontSize: 9,
        color: 'white',
        fontFamily: 'Helvetica-Bold',
    },

    containerTable: {
        width: "100%",
        marginTop: 15,
        flexDirection: "column",
        borderTop: '1px solid #D8E7F5',
        borderLeft: '1px solid #D8E7F5',
        borderRight: '1px solid #D8E7F5',
    },

    line: {
        flexDirection: 'row',
        width: '100%',
        fontSize: 9,
        borderBottom: '1px solid #D8E7F5'
    },

    header: {
        width: '30%',
        textAlign: 'left',
        fontFamily: 'Helvetica-Bold',
        borderRight: '1px solid #D8E7F5',
        padding: 8,
    },

    content: {
        width: '70%',
        textAlign: 'left',
        color: '#555555',
        padding: 8,
    }
})


export default function Technical(props) {

    const {t} = props.pdfTranslation


    return (
        <>
            <View>
                <Header text={t('technical.title')}/>

                <View style={styles.containerMaster}>
                    {props.data.masteredTechnologies.map((object, index) =>
                        <View key={index} style={styles.object}>
                            <View>
                                <Text style={styles.title}>{object.title}</Text>
                            </View>

                            <View style={styles.level}>
                                {[...Array(5)].map((x, i) =>
                                    <View key={i} style={[styles.item, {opacity: (i >= object.level) ? 0.4 : 1}]}>
                                        {object.level === i + 1 && <Text style={styles.indicator}>{object.level}</Text>}
                                    </View>
                                )}
                            </View>
                        </View>
                    )}
                </View>

                <View style={styles.containerTable}>
                    {props.data.skillsTable.map((object, index) => 
                        <View key={index} style={styles.line}>
                            <View style={styles.header}><Text>{object.title}</Text></View>
                            <View style={styles.content}><Text>{object.content}</Text></View>    
                        </View>
                    )}
                </View>
            </View>
        </>
    )
}