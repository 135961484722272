import React, { useState } from "react";
import classes from "./forms.module.scss";
import useSettings from "../../helpers/hooks/useSettings";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MyFade from "../MyFade";
import { reorder } from "../../helpers/array-manager";
import RangePicker from "../RangePicker";
import moment from "moment";

const StringForm = (props) => {
  const isEditable = useSettings().settings.edit;
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [newObject, setNewObject] = useState({
    [props.keysName[0]]: "",
    [props.keysName[1]]: "",
    period: null,
    content: "",
  });
  const [rangeState, setRangeState] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });

  let objects = props.data;
  props.properties.forEach((prop) => {
    objects = objects[prop];
  });
  const isObjectCreatable =
    newObject[props.keysName[0]] !== "" &&
    newObject[props.keysName[1]] &&
    newObject.period !== null;

  const handleChange = (event, prop) => {
    const _newObject = { ...newObject };
    _newObject[prop] = event.currentTarget.value;
    setNewObject(_newObject);
  };

  const handleRangeChange = (range) => {
    const _newObject = { ...newObject };
    _newObject.period =
      moment(range.startDate).format("MM/YYYY") +
      " - " +
      moment(range.endDate).format("MM/YYYY");
    setNewObject(_newObject);
  };

  const addNewObject = () => {
    const updated_objects = [...objects];
    updated_objects.push(newObject);
    props.update(props.properties, updated_objects);
    setNewObject({
      [props.keysName[0]]: "",
      [props.keysName[1]]: "",
    });
    setRangeState({
      startDate: null,
      endDate: null,
      key: "selection",
    });
    setCount1(0);
    setCount2(0);
  };

  const onDragEnd = (result) => {
    if (!result.destination || !isEditable) return;

    const updated_objects = reorder(
      [...objects],
      result.source.index,
      result.destination.index
    );

    props.update(props.properties, updated_objects);
  };

  const removeObject = (id) => {
    const updated_objects = [...objects];
    updated_objects.splice(id, 1);
    props.update(props.properties, updated_objects);
  };

  const onContentUpdate = (content, id) => {
    const updated_objects = [...objects];
    updated_objects[id].content = content;
    props.update(props.properties, updated_objects);
  };

  return (
    <div
      className={
        isEditable ? classes.group : classes.group + " " + classes.disabled
      }
      style={{ height: "100%" }}
    >
      <div className={classes.lister}>
        <div className={classes.top}>
          <p className={classes.text}>{props.title} :</p>

          <div className={classes.right}>
            <input
              type={"text"}
              value={newObject[props.keysName[0]]}
              placeholder={props.placeholder1}
              maxlength="256"
              style={{ margin: "0 5px" }}
              disabled={!isEditable}
              onChange={(e) => {
                handleChange(e, props.keysName[0]);
                setCount1(e.target.value.length);
              }}
            />
            <div className={classes.charCount}>
              <span>{count1}</span>
              <span>/256</span>
            </div>

            <input
              type={"text"}
              value={newObject[props.keysName[1]]}
              placeholder={props.placeholder2}
              maxlength="256"
              style={{ margin: "0 5px" }}
              disabled={!isEditable}
              onChange={(e) => {
                handleChange(e, props.keysName[1]);
                setCount2(e.target.value.length);
              }}
            />
            <div className={classes.charCount}>
              <span>{count2}</span>
              <span>/256</span>
            </div>

            <RangePicker
              rangeState={rangeState}
              setRangeState={setRangeState}
              disabled={!isEditable}
              onChange={handleRangeChange}
            />

            <button
              onClick={addNewObject}
              disabled={!(isEditable && isObjectCreatable)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classes.keys}
              >
                {objects.map((object, index) => (
                  <MyFade key={index}>
                    <Draggable
                      key={index.toString()}
                      draggableId={index.toString()}
                      index={index}
                    >
                      {(provided, snapshot) =>
                        props.enableSubList ? (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classes.key + " " + classes.complexList}
                          >
                            <ListItem
                              object={object}
                              keysName={props.keysName}
                              isEditable={isEditable}
                              removeObject={() => removeObject(index)}
                              enableSubList={props.enableSubList}
                              updateContent={(content) =>
                                onContentUpdate(content, index)
                              }
                            />
                          </div>
                        ) : (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classes.key}
                          >
                            <div className={classes.container}>
                              <table>
                                <tr>
                                  <td style={{ fontWeight: 500 }}>
                                    {object[props.keysName[0]]}
                                  </td>
                                  <td style={{ fontWeight: 300 }}>
                                    {object[props.keysName[1]]}
                                  </td>
                                  <td>{object.period}</td>
                                </tr>
                              </table>
                            </div>

                            <button
                              onClick={removeObject}
                              disabled={!isEditable}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </button>
                          </div>
                        )
                      }
                    </Draggable>
                  </MyFade>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default StringForm;

const ListItem = (props) => {
  const [count, setCount] = useState(
    props !== null ? props.object.content.length : 0
  );
  const { t } = useTranslation(["forms"]);

  return (
    <>
      <div className={classes.header}>
        <div className={classes.content}>
          <table>
            <tr>
              <td style={{ fontWeight: 500 }}>
                {props.object[props.keysName[0]]}
              </td>
              <td style={{ fontWeight: 300 }}>
                {props.object[props.keysName[1]]}
              </td>
              <td>{props.object.period}</td>
            </tr>
          </table>
        </div>

        <button onClick={props.removeObject} disabled={!props.isEditable}>
          <FontAwesomeIcon icon={faMinus} />
        </button>
      </div>

      <textarea
        value={props.object.content}
        maxLength="3500"
        onChange={(e) => {
          props.updateContent(e.currentTarget.value);
          setCount(e.target.value.length);
        }}
        disabled={!props.isEditable}
      />
      <div className={classes.charCount} style={{ width: "100%" }}>
        <span>{count}</span>
        <span>/3500 {t("text.counter")}</span>
      </div>
    </>
  );
};
