import React from "react"
import MyFade from "../../../components/MyFade";
import classes from "./sections.module.scss";
import {useTranslation} from "react-i18next";
import useData from "../../../helpers/hooks/useData";
import SliderLister from "../../../components/forms/SliderLister";
import TextareaLister from "../../../components/forms/TextareaLister";


const technologies = [
    "LARAVEL", "JAVASCRIPT", "CSS3 / CSS4", "REACT.JS", "REACT NATIVE", "DRUPAL", "SYMFONY", "WORDPRESS", "HTML5",
    "VUE.JS", "ANGULAR", "PHP", "API PLATFORM", "UI/UX", "MANAGEMENT", "ZEND", "PRESTASHOP", "MAGENTO", "NODEJS", "JQUERY", 
    "TWIG", "SASS", "ES6", "DOCKER", "BASH / SHELL", "IOS", "ANDROID", ".NET CORE", "XAMARIN", "ASP .NET", "ASP .NET CORE", "SITEFINITY", "SITECORE",
    "WPF", "UWP", "MS SQL", "ORACLE DB", "MYSQL", "JAVA", "LINUX", "POWERSHELL"]


const Technical = (props) => {

    const { t } = useTranslation(['forms'])
    const { data, updateProperties } = useData()


    return(
        <MyFade>
            <div className={classes.container}>
                <div className={classes.column}>
                    <SliderLister title={t("technical.mastered.title")} data={data} properties={["technical", "masteredTechnologies"]} update={updateProperties}
                                  list={technologies.sort((a, b) => a.localeCompare(b))}/>
                </div>

                <hr className={classes.vSeparator}/>

                <div className={classes.column}>
                    <TextareaLister title={t("technical.table.title")} data={data} properties={["technical", "skillsTable"]} update={updateProperties} 
                                    list={t("technical.table.list", {returnObjects: true})}/>
                </div>  
            </div>
        </MyFade>
    )
}


export default Technical