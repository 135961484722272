import React from "react";
import "./style.scss";
import logo from "../../res/images/esokia_logo.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedo} from "@fortawesome/free-solid-svg-icons";
import {url} from "../../conf/env";


const AuthenticationFail = (props) => {

    return(
        <div id={"authentication-fail"}>
            <img src={logo} alt={"Esokia Logo"}/>
            <h1>SKILLS</h1>

            <div id={"message"}>
                {props.message}
            </div>
            
            <div id={"redirection"}>
                <a href={url.skills}><FontAwesomeIcon className={"icon"} icon={faRedo}/></a>
                <a href={url.sso} className={"icon"}>SSO</a>
            </div>
        </div>
    )
}


export default AuthenticationFail