import React from "react"
import { useTranslation } from 'react-i18next';
import classes from "./sections.module.scss"
import useData from "../../../helpers/hooks/useData";
import MyFade from "../../../components/MyFade";
import StringForm from "../../../components/forms/StringForm";


const Project = () => {

        const { t } = useTranslation(['forms'])
        const { data, updateProperties } = useData()


        return (
            <MyFade>
                <div className={classes.container}>
                    <div className={classes.column} style={{width: '100%'}}>
                        <StringForm title={t('project.title')} data={data} properties={["project"]}
                                    keysName={["name", "type"]}
                                    placeholder1={t('project.placeholder.name')} placeholder2={t('project.placeholder.type')}
                                    update={updateProperties} enableSubList />
                    </div>
                </div>
            </MyFade>
        )
}


export default Project