import React from "react"
import {Text, View} from "@react-pdf/renderer";
import Header from "../components/Header";
import {StyleSheet} from '@react-pdf/renderer'


export const styles = StyleSheet.create({
    container: {
        width: '100%'
    },

    line: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        fontSize: 10,
        marginTop: 3
    },

    title: {
        textAlign: 'left',
        fontFamily: 'Helvetica-Bold',
    },

    content: {
        textAlign: 'left',
        color: '#555555',
        marginLeft: 7
    }
})


export default function Language(props) {

    const {t} = props.pdfTranslation


    return(
        <>
            <View>
                <Header text={t('language.title')}/>
                <View style={styles.container}>
                    {props.data.map((object, index) => 
                        <View key={index} style={styles.line}>
                            <Text style={styles.title}>{object.title} :</Text>
                            <Text style={styles.content}>{object.option}</Text>
                        </View>
                    )}
                </View>
            </View>
        </>
    )
}