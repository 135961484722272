import React, {useState} from "react"
import classes from "./modal.module.scss"
import { Modal } from 'react-responsive-modal';
import {changeSelectedEmployee, getInitialSkillsData} from "../../providers/provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck, faTimes, faUser} from "@fortawesome/free-solid-svg-icons";
import MyFade from "../MyFade";
import useData from "../../helpers/hooks/useData";
import { store as notificationStore } from 'react-notifications-component';
import Notification from "../Notifications";
import {useTranslation} from "react-i18next";
import useSettings from "../../helpers/hooks/useSettings";


export default function ModalChangeUser(props) {

    const [filter, setFilter] = useState("")
    const skillsData = getInitialSkillsData()
    const authData = props.authData
    const updateDataContext = useData().updateData
    const updateEditOption = useSettings().updateEditOption
    const t  = useTranslation(['modal', 'notification']).t



    const handleCloseModal = () => {
        props.handleClose()
    }


    const handleFilterChange = (e) => {
        setFilter(e.currentTarget.value)
    }


    const handleSwitchSkillUser = (id) => {
        const newSelectedEmployee = changeSelectedEmployee(id)
        updateEditOption(false)
        updateDataContext({...newSelectedEmployee.qualifications})
        props.handleClose()
        notificationStore.addNotification({
            insert: "bottom",
            container: "bottom-left",
            content: <Notification text={t("notification:success.switch") + `${newSelectedEmployee.firstName} ${newSelectedEmployee.lastName}`} type={"success"}/>,
            dismiss: {
                duration: 3000
            }
        })
    }


    return (
        <Modal open={props.open} onClose={handleCloseModal} center>
            <div className={classes.container}>
                <h2>{t("title")}</h2>
                <input type={"text"} className={classes.filter} placeholder={t("placeholder")}
                       value={filter} onChange={handleFilterChange}/>

                <div className={classes.list}>
                    {skillsData.filled_employees
                        .filter((employee) => (employee.firstName.toLowerCase() + " " +  employee.lastName.toLowerCase()).includes(filter.toLowerCase()))
                        .sort((a, b) => a.firstName.localeCompare(b.firstName))
                        .map((employee, index) =>

                        <MyFade key={index}>
                            <div className={classes.item} style={(!employee.enabled)?{borderLeft: "2px solid red"} : undefined}
                                 onClick={() => handleSwitchSkillUser(employee.id)}>

                                <p>{employee.firstName + " " + employee.lastName}</p>
                                <span>
                                    {employee.id === authData.payload.api_id && <FontAwesomeIcon style={{color: "#272727"}}  icon={faUser}/>}
                                    {(employee.qualifications.en.id === null)?
                                        <FontAwesomeIcon style={{color: "red"}} icon={faTimes}/> : <FontAwesomeIcon style={{color: "rgb(29,195,231)"}} icon={faCheck}/>
                                    }
                                </span>
                            </div>
                        </MyFade>
                    )}
                </div>
            </div>
        </Modal>
    )
}