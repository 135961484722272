import { Redirect } from "react-router-dom";
import Skills from "../pages/Skills";
import Profile from "../pages/Skills/sections/Profile";
import Pdf from "../pages/Pdf";
import About from "../pages/Skills/sections/About"
import Technical from "../pages/Skills/sections/Technical";
import Language from "../pages/Skills/sections/Language";
import Formation from "../pages/Skills/sections/Formation";
import Experience from "../pages/Skills/sections/Experience";
import Project from "../pages/Skills/sections/Project";


export const routes =  [
    {
        path: "/",
        exact: true,
        comp: () => <Redirect to={"/skills"}/>
    },
    {
        path: "/skills",
        exact: false,
        comp: (authData) => <Skills authData={authData}/>
    },
    {
        path: "/pdf",
        exact: true,
        comp: (authData) => <Pdf authData={authData}/>
    }
]


export const skills_routes = [
    {
        path: "/skills",
        exact: true,
        comp: (props) => <About props={props}/>
    },
    {
        path: "/skills/profile",
        exact: true,
        comp: (props) => <Profile props={props}/>
    },
    {
        path: "/skills/technical-skills",
        exact: true,
        comp: (props) => <Technical props={props}/>
    },
    {
        path: "/skills/language",
        exact: true,
        comp: (props) => <Language props={props}/>
    },
    {
        path: "/skills/formation",
        exact: true,
        comp: (props) => <Formation props={props}/>
    },
    {
        path: "/skills/experience",
        exact: true,
        comp: (props) => <Experience props={props}/>
    },
    {
        path: "/skills/project",
        exact: true,
        comp: (props) => <Project props={props}/>
    },
]