import React, { useState } from "react";
import classes from "./forms.module.scss";
import useSettings from "../../helpers/hooks/useSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MyFade from "../MyFade";
import { reorder } from "../../helpers/array-manager";

export default function SelectLister(props) {
  const isEditable = useSettings().settings.edit;
  const [newObject, setNewObject] = useState({
    title: props.list[0],
    option: "",
  });
  let objects = props.data;
  props.properties.forEach((prop) => {
    objects = objects[prop];
  });
  const isObjectExisting =
    objects.find((object) => object.title === newObject.title) !== undefined;

  const handleChange = (event) => {
    setNewObject({
      title: event.currentTarget.value,
      option: "",
    });
  };

  const addNewObject = () => {
    const updated_objects = [...objects];
    updated_objects.push(newObject);
    props.update(props.properties, updated_objects);
  };

  const removeObject = (id) => {
    const updated_objects = [...objects];
    updated_objects.splice(id, 1);
    props.update(props.properties, updated_objects);
  };

  const onDragEnd = (result) => {
    if (!result.destination || !isEditable) return;

    const updated_objects = reorder(
      [...objects],
      result.source.index,
      result.destination.index
    );

    props.update(props.properties, updated_objects);
  };

  const handleUpdate = (e, id) => {
    const updated_objects = [...objects];
    updated_objects[id].option = e.currentTarget.value;
    props.update(props.properties, updated_objects);
  };

  return (
    <div
      className={
        isEditable ? classes.group : classes.group + " " + classes.disabled
      }
      style={{ height: "100%" }}
    >
      <div className={classes.lister}>
        <div className={classes.top}>
          <p className={classes.text}>{props.title} :</p>

          <div className={classes.right}>
            <select disabled={!isEditable} onChange={handleChange}>
              {props.list.map((el, index) => (
                <option key={index}>{el}</option>
              ))}
            </select>
            <button
              onClick={addNewObject}
              disabled={!(isEditable && !isObjectExisting)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classes.keys}
              >
                {objects.map((object, index) => (
                  <MyFade key={index}>
                    <Draggable
                      key={index.toString()}
                      draggableId={index.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classes.key}
                        >
                          <div className={classes.container}>
                            <p>{object.title}</p>
                            <select
                              value={object.option}
                              onChange={(e) => handleUpdate(e, index)}
                            >
                              {props.sublist.map((el, index) => (
                                <option key={index}>{el}</option>
                              ))}
                            </select>
                          </div>

                          <button
                            onClick={() => removeObject(index)}
                            disabled={!isEditable}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                        </div>
                      )}
                    </Draggable>
                  </MyFade>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}
