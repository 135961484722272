import React from "react";
import classes from "./style.module.scss"
import Usercard from "../../components/overview/Usercard";
import {Route, NavLink} from "react-router-dom"
import MainButton from "../../components/buttons/MainButton";
import Switch from "react-switch";
import { useTranslation } from 'react-i18next';
import { skills_routes } from "../../conf/routes";
import useSettings from "../../helpers/hooks/useSettings";
import Document from "../../components/pdf/Document"
import useData from "../../helpers/hooks/useData";
import downloadCv from "../../helpers/download-object";
import {faDownload, faEye, faSave} from "@fortawesome/free-solid-svg-icons";
import saveSkills from "../../providers/save";
import { store as notificationStore } from 'react-notifications-component';
import Notification from "../../components/Notifications";
import {getInitialSkillsData} from "../../providers/provider";


const Skills = (props) => {

    const { t, i18n } = useTranslation(['overview', 'notification']);
    const { authData } = props
    const dataContext = useData()
    const data = dataContext.data
    const { settings, updateEditOption, updateLangOption } = useSettings()
    const editLocked = settings.edit
    const selectedEmployee = getInitialSkillsData().selected_employee


    const handleLockSwitch = () => {
        updateEditOption(!editLocked)
    }


    const setLanguage = (new_lang) => {
        updateLangOption(new_lang)
    }


    const saveData = () => {
        notificationStore.addNotification({
            insert: "bottom",
            container: "bottom-left",
            content: <Notification text={t('notification:info.save')} type={"info"}/>,
            dismiss: {
                duration: 1500
            }
        })

        saveSkills(dataContext.getAllSkills())
            .then(() => {
                notificationStore.addNotification({
                    insert: "bottom",
                    container: "bottom-left",
                    content: <Notification text={t('notification:success.save')} type={"success"}/>,
                    dismiss: {
                        duration: 3000
                    }
                })
            })
            .catch((err) => {
                console.error(err)
                notificationStore.addNotification({
                    insert: "bottom",
                    container: "bottom-left",
                    content: <Notification text={t('notification:error.save')} type={"error"}/>,
                    dismiss: {
                        duration: 3000
                    }
                })
            })
    }


    const generatePdf = () => {
        downloadCv(
            <Document fullName={selectedEmployee.firstName + " " + selectedEmployee.lastName.toUpperCase()} data={data}/>,
            selectedEmployee.firstName + "_" + selectedEmployee.lastName.toUpperCase(),
            t('notification:success.download')
        )
    }


    return (
        <div id={"page"}>

            <div className={classes.menu}>
                <nav>
                    <NavLink to={"/skills/profile"} activeClassName={classes.active} exact>{t('menu.profile')}</NavLink>
                    <NavLink to={"/skills/technical-skills"} activeClassName={classes.active} exact>{t('menu.tech')}</NavLink>
                    <NavLink to={"/skills/language"} activeClassName={classes.active} exact>{t('menu.lang')}</NavLink>
                    <NavLink to={"/skills/formation"} activeClassName={classes.active} exact>{t('menu.formation')}</NavLink>
                    <NavLink to={"/skills/experience"} activeClassName={classes.active} exact>{t('menu.experience')}</NavLink>
                    <NavLink to={"/skills/project"} activeClassName={classes.active} exact>{t('menu.project')}</NavLink>
                </nav>

                <div className={classes.option}>
                    <div className={classes.edit}>
                        {(selectedEmployee.id === authData.payload.api_id
                            || authData.payload.roles.includes("ROLE_ADMIN")) &&
                            <>
                                <span>{t('buttons.mode')}</span>
                                <Switch onChange={handleLockSwitch} checked={editLocked}
                                        handleDiameter={25}
                                        onColor="#1dc3e7"
                                        offColor="#37393A"
                                        height={20}
                                        width={45} />
                            </>
                        }
                    </div>

                    <div className={classes.lang}>
                    <span onClick={() => setLanguage("fr")}
                          className={(i18n.language === "fr")? classes.active : undefined}>FR</span>
                        <span onClick={() => setLanguage("en")}
                              className={(i18n.language === "en")? classes.active : undefined}>EN</span>
                    </div>
                </div>
            </div>

            <div className={classes.overview}>
                <section className={classes.skills}>
                    {skills_routes.map((route, index) =>
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.comp}
                        />
                    )}
                </section>

                <section className={classes.profile}>
                    <Usercard authData={authData}/>

                    <div className={classes.action}>
                        <MainButton text={t('buttons.download')} icon={faDownload} onClick={generatePdf} light/>
                        <MainButton text={t('buttons.see')} icon={faEye} to={"/pdf"} light/>
                        <MainButton text={t('buttons.save')} icon={faSave} onClick={saveData} disabled={!editLocked}/>
                    </div>
                </section>
            </div>
        </div>
    )
}


export default Skills