import React, {useState} from "react"
import classes from "./forms.module.scss"
import useSettings from "../../helpers/hooks/useSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../helpers/array-manager";
import MyFade from "../MyFade";
import {store as notificationStore} from "react-notifications-component";
import Notification from "../Notifications";
import {useTranslation} from "react-i18next";


export default function StringLister(props) {

    const { t } = useTranslation(['notification'])
    const isEditable = useSettings().settings.edit
    const [newKeys, setNewKeys] = useState("")
    let keys = props.data
    props.properties.forEach((prop) => {
        keys = keys[prop]
    })


    const handleChange = (event) => {
        setNewKeys(event.target.value)
    }


    const addNewKeys = () => {
        if(newKeys !== ""){
            if(keys.length < 5) {
                const updated_keys = [...keys]
                updated_keys.push(newKeys)
                props.update(props.properties, updated_keys)
                setNewKeys("")
            }
            else
                notificationStore.addNotification({
                    insert: "bottom",
                    container: "bottom-left",
                    content: <Notification text={t("warning.maxKeys")} type={"warning"}/>,
                    dismiss: {
                        duration: 5000
                    }
                })
        }
    }


    const removeKeys = (id) => {
        const updated_keys = [...keys]
        updated_keys.splice(id, 1)
        props.update(props.properties, updated_keys)
    }


    const onDragEnd = (result) => {
        if (!result.destination || !isEditable) return;
    
        const updated_keys = reorder(
            [...keys],
            result.source.index,
            result.destination.index
        );
    
        props.update(props.properties, updated_keys)
    }


    return (
        <div className={(isEditable)? classes.group : classes.group + " " + classes.disabled} style={{height: '100%'}}>

            <div className={classes.lister}>
                <div className={classes.top}>
                    <p className={classes.text}>{props.title} :</p>

                    <div className={classes.right}>
                        <input type="text" value={newKeys} onChange={handleChange} disabled={!isEditable} placeholder={props.placeholder}/>
                        <button onClick={addNewKeys} disabled={!isEditable}><FontAwesomeIcon icon={faPlus}/></button>
                    </div>
                </div>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}
                                className={classes.keys}>

                                {keys.map((key, index) =>
                                    <MyFade key={index}>
                                        <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                                            {(provided, snapshot) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                         className={classes.key}>

                                                        <div className={classes.container}>
                                                            <p>{key}</p>
                                                        </div>
                                                        <button onClick={() => removeKeys(index)} disabled={!isEditable}><FontAwesomeIcon icon={faMinus}/></button>
                                                    </div>
                                            )}
                                        </Draggable>
                                    </MyFade>
                                )}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    )
}