import { faCheckCircle, faExclamationCircle, faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import classes from "./notification.module.scss"


export default function Notification(props) {

    let icon = faInfoCircle
    let color = 'rgb(29,195,231)'

    if(props.type === "error") {
        icon = faExclamationCircle
        color = '#DC3545'
    }
    else if(props.type === "warning") {
        icon = faExclamationTriangle
        color = '#EAB000'
    }
    else if(props.type === "success") {
        icon = faCheckCircle
        color = '#28A745'
    }


    return(
        <div className={classes.notification} style={{backgroundColor: color}}>
            <FontAwesomeIcon icon={icon}/>
            <p>
                {props.text}
            </p>
        </div>
    )
}