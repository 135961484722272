import React from "react"
import { useTranslation } from 'react-i18next';
import classes from "./sections.module.scss"
import useData from "../../../helpers/hooks/useData";
import MyFade from "../../../components/MyFade";
import StringForm from "../../../components/forms/StringForm";


const Experience = () => {

        const { t } = useTranslation(['forms'])
        const { data, updateProperties } = useData()


        return (
            <MyFade>
                <div className={classes.container}>
                    <div className={classes.column} style={{width: '100%'}}>
                        <StringForm title={t('experience.title')} data={data} properties={["experience"]}
                                    keysName={["job", "company"]}
                                    placeholder1={t('experience.placeholder.job')} placeholder2={t('experience.placeholder.company')}
                                    update={updateProperties} enableSubList />
                    </div>
                </div>
            </MyFade>
        )
}


export default Experience