import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import * as serviceWorker from './serviceWorker'
import {checkAuthentication} from "./helpers/security"
import AuthenticationFail from "./pages/Authentication/fail"
import App from "./App"
import logo from "./res/images/esokia_logo.svg"
import loader from "./res/icons/loader.svg"
import "./lang/i18n"
import startSkillsDataLoading from "./providers/provider";


ReactDOM.render(
    <div id={"auth-loading"}>
        <img src={logo} alt={"Esokia Logo"}/>
        <h1>SKILLS</h1>
        <div>
            AUTHENTICATION IN PROGRESS
            <img src={loader} alt={"loader"}/>
        </div>
    </div>
    , document.getElementById('root'));


checkAuthentication
    .then((auth_data) => {

        startSkillsDataLoading(auth_data)

        ReactDOM.render(
            <div id={"app"}>
                {(auth_data !== null) ?

                    <App authData={auth_data}/>

                    :

                    <div id={"app_content"}>
                        <AuthenticationFail message={"AUTHENTICATION FAILED!"}/>
                    </div>
                }
            </div>
            , document.getElementById('root'));
    });


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
